<template>
<div class="container">
    <provider-account v-if="auth('PROVIDER')"/>
    <org-account      v-else-if="auth('ORG')"/>
    <school-account   v-else-if="auth('SCHOOL', 'ORG_SCHOOL')"/>
    <all-account      v-else-if="auth('ADMIN')"/>
</div>
</template>

<script>
import Core from '@/core';

export default {
    components: {
        ProviderAccount: () => import('./components/ProviderAccount.vue'),
        OrgAccount: () => import('./components/OrgAccount.vue'),
        SchoolAccount: () => import('./components/SchoolAccount.vue'),
        AllAccount: () => import('./components/AllAccount.vue'),
    },
    props: {},
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
    },
    methods: {
        auth: Core.Utils.auth,
    },
};
</script>